import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { FileDownloadHandlerProps } from "./types";
import { useErrorToastr } from "hooks/useErrorToastr";
import { StatusHandler, StatusHandlerHelpers } from "components/utils";
import { fileDownloader } from "fileDownloader";

export const FileDownloadHandler = ({ children, factoryFn, type }: FileDownloadHandlerProps) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  const handleDownload = async (helpers: StatusHandlerHelpers) => {
    helpers.startFetching();
    const tstr = downloadFeedbackToastr.open({ type });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url: factoryFn().url,
      name: factoryFn().name,
      type,
    });
    console.log({ response });
    if (response.status === "success") {
      helpers.stopFetching();
      tstr.lazyClose();
    } else {
      helpers.stopFetching();
      tstr.close();
      handleErrorMessage(response);
    }
  };

  return (
    <StatusHandler>
      {helpers =>
        children({ download: () => handleDownload(helpers), isLoading: helpers.isFetching })
      }
    </StatusHandler>
  );
};
